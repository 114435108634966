// src/components/Contact.tsx
import axios from "axios";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as styles from "./Contact.module.css";

interface ContactProps {
  apiUrl: string;
}

interface FormData {
  name: string;
  email: string;
  message: string;
}

const Contact: React.FC<ContactProps> = ({ apiUrl }) => {
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = handleSubmit((data) => {
    console.log(data);
    goSubmit();
  });

  const goSubmit = async () => {
    if (step === 1) {
      setStep(2); // ステップ2に移行
    } else {
      setIsPushed(true); // step2で送信ボタンを押したら、送信中ボタンに変更
      console.log(formData); // ここでデータを処理
      try {
        await axios
          .post(apiUrl, {
            name: formData.name,
            email: formData.email,
            message: formData.message,
          })
          .then((response) => {
            console.log(response);
            setStep(3); // ステップ3に移行
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleBack = () => {
    setStep(1); // ステップ1に戻る
  };
  
  const [isPushed, setIsPushed] = useState(false);

  return (
    <section className={styles.contact}>
      <div className={styles.contactInner}>
        {step === 1 && (
          <div>
            <div className={styles.contactTitle}>
              <h2>
                お問い合わせは
                <br />
                こちらのフォームより承っております。
              </h2>
              <p>ご返信に3営業日ほどお時間をいただいております。</p>
              <p>
                3営業日を過ぎても返信がない場合は、お手数ですが再度お問い合わせをお願いいたします。
              </p>
            </div>
            <form onSubmit={onSubmit} className={styles.contactForm}>
              <label htmlFor="name">お名前</label>
              <input
                type="text"
                {...register("name", { required: true })}
                onChange={handleChange}
                placeholder="山田 太郎"
              />
              {errors.name && <p className={styles.error}>名前は必須です。</p>}
              <label htmlFor="email">メールアドレス</label>
              <input
                type="email"
                {...register("email", { required: true })}
                onChange={handleChange}
                placeholder="sample@sample.com"
              />
              {errors.email && (
                <p className={styles.error}>メールアドレスは必須です。</p>
              )}
              <label htmlFor="message">お問い合わせ内容</label>
              <textarea
                {...register("message", { required: true })}
                onChange={handleChange}
                placeholder="お問い合わせ内容をご記入ください。"
              />
              {errors.message && (
                <p className={styles.error}>お問い合わせ内容は必須です。</p>
              )}
              <div className={styles.privacyLabelContainer}>
                <input
                  type="checkbox"
                  {...register("privacy", { required: true })}
                  id="privacy"
                  className={styles.privacyCheckbox}
                />
                <label htmlFor="privacy">
                  <a href="/companyPrivacy">個人情報保護方針</a>に同意する
                </label>
              </div>
              {errors.privacy && (
                <p className={styles.error}>
                  個人情報保護方針への同意が必要です。
                </p>
              )}

              <button type="submit" className={styles.btnSubmit}>
                確認画面へ
              </button>
            </form>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2>
              ご入力ありがとうございます！
              <br />
              内容をご確認いただき「送信する」を押してください。
            </h2>
            <div className={styles.formConfirm}>
              <dl>
                <dt>お名前</dt>
                <dd>{formData.name}</dd>
              </dl>
              <dl>
                <dt>メールアドレス</dt>
                <dd>{formData.email}</dd>
              </dl>
              <dl>
                <dt>お問い合わせ内容</dt>
                <dd>{formData.message}</dd>
              </dl>
            </div>
            <button className={styles.btnBack} onClick={handleBack}>
              戻る
            </button>
            <button 
              className={`${styles.btnSubmit2} ${isPushed ? styles.btnPushed : ''}`} 
              onClick={goSubmit} 
              disabled={isPushed}
            >
              {isPushed ? '送信中...' : '送信する'}
            </button>
          </div>
        )}

        {step === 3 && (
          <div>
            <h2>お問い合わせありがとうございます!</h2>
            <p>
              入力されたメールアドレスに回答をいたします、しばらくお待ちください。
            </p>
            <br />
            <button className={styles.btnSubmit} onClick={() => navigate("/")}>
              トップページに戻る
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Contact;
