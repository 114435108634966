// extracted by mini-css-extract-plugin
export var btnBack = "Contact-module--btnBack--51144";
export var btnPushed = "Contact-module--btnPushed--b9100";
export var btnSubmit = "Contact-module--btnSubmit--bc094";
export var btnSubmit2 = "Contact-module--btnSubmit2--ad54e";
export var contact = "Contact-module--contact--f2525";
export var contactForm = "Contact-module--contactForm--f6f9e";
export var contactInner = "Contact-module--contactInner--f5290";
export var contactTitle = "Contact-module--contactTitle--999e9";
export var error = "Contact-module--error--5e364";
export var formConfirm = "Contact-module--formConfirm--2b75c";
export var privacy = "Contact-module--privacy--f9966";
export var privacyCheckbox = "Contact-module--privacyCheckbox--9afef";
export var privacyLabelContainer = "Contact-module--privacyLabelContainer--8482e";